@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Crete+Round&display=swap');

.icon {
    width: 50px;
    height: 50px;

}

.fadeIn {
    -webkit-animations: fadeIn ease 1.5s;
     animation: fadeIn ease 3.5s;

}

#gameFont {
    font-family: 'Press Start 2P';
}

#featured {
        float: left;
        height: auto;
        background-size: cover;
        border: 2px solid #ffffff;
        padding: 5px 40px;
        background: #ffffff;
}

#featured:hover {
    border-color:yellow;
}

p {
    font-family: 'Crete Round';
    position:relative;
    font-size: calc(13px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
    text-align:left;
    color:gray;
}

#s {
     font-family: 'Crete Round';
        position:relative;
        font-size: calc(10px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
        text-align:left;
        color:gray;
}

#cardText {
    font-family:'Crete Round';
    font-size:calc(5px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
}


h2,h3,h4 {
    font-family: 'Crete Round';
}

.toRight {
    position:relative;
    animation: toRight ease 1.5s;
    -webkit-animations: toRight ease 1.5s;
}

.toLeft {
    position:relative;
    animation: toLeft ease 1.5s;
    -webkit-animations: toLeft ease 1.5s;
}
@keyframes toLeft {
    0% {left: 100%; }
    100% {left: 65%; }
}

@-webkit-keyframes toLeft {
    0% {left: 100%; }
    100% {left: 65%; }
}
@keyframes toRight {
    0% {left: 0%; }
    100% {left: 1%; }
}

@-webkit-keyframes toRight {
    0% {left: 0%; }
    100% {left: 1%; }
}



@keyframes fadeIn {
    0% {opacity: 0.0;}
    100% {opacity:1.0;}
}

@-webkit-keyframes fadeIn {
    0% { opacity: 0.0; }
    100% { opacity: 1.0; }
}


