/*https://css-tricks.com/snippets/css/typewriter-effect/ */
@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');

.typewriter h1 {
    overflow: hidden; /* Ensures the contest is not revealed until the animation */
    border-right: .20em solid orange; /* the typewriter cursor */
    white-space: nowrap; /* Keeps the content on a single line*/
    margin: 0 auto; /* Gives the scrolling effect as the typing happens */
    letter-spacing: .15em;
    animation:
        typing 3.5s steps(40, end),
        blink-caret .5s step-end infinite;

}

h1 {
    font-family:'Sacramento';

}


.typewriter {
    display:inline-block;
}


@keyframes typing {
    from {width: 0}
    to { width: 100% }
}

@keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: orange; }
}